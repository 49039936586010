.mapboxgl-ctrl-bottom-right{
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  }
  /* @media (max-width: 960px) {
    .mapboxgl-ctrl-top-right {
      margin-top: 133px;
      margin-right: 6px;
      z-index: 1;
    }
  } */
dt{
  font-weight: bold;
}
.mapboxgl-ctrl.mapboxgl-ctrl-group {
  display: flex;
  margin: 0px;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url('svg/icon+.svg') !important;
  background-color: #db8b00;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url('svg/icon-.svg') !important;
  background-color: #db8b00;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
  background-image: url('svg/iconcompass.svg')!important;
  background-color: #db8b00;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  background-image: url('svg/geolocate.svg') !important;
  background-color: #db8b00;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon{
  background-image: url('svg/fullscreen.svg') !important;
  background-color: #db8b00;
}
.maplibregl-export-control {
  background-image: url('svg/export.svg')!important;
  background-color: #db8b00;
}